import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/login.scss'

const Login = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${apiUrl}/api/Auth/login`, {email, password});
            localStorage.setItem('token', response.data.token);  // Store the token
            navigate('/');
        } catch (error) {
            console.error('Login error:', error);
        }
    };

    return (
        <div className="login-root">
            <div className="login-app">
                <div className="login-container">
                    <div className="login-card">
                        <h1 className="login-title">Sign in</h1>
                        <p className="login-subtitle">Sign in with your email address</p>
                        <form onSubmit={handleSubmit}>
                            <div className="input-group">
                                <input
                                    type="email"
                                    placeholder="Email Address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    className="login-input"
                                />
                            </div>
                            <div className="input-group">
                                <input
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    className="login-input"
                                />
                            </div>
                            <button type="submit" className="login-button">Sign in</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;