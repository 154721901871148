import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { searchBusinesses } from '../services/BusinessServices';  // Your existing business search function
import { geocodeZipCodeWithOpenCage } from '../components/geocodeApi';  // Your OpenCage geocoding function
import Navbar from '../components/Navbar';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '../styles/common.scss';
import SearchBar from "../components/SearchBar";

// Reset Leaflet's default icon paths
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png'
});

// Custom marker icon (optional)
const customIcon = new L.Icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    shadowSize: [41, 41]
});

const containerStyle = {
    width: '100%',
    height: '400px',
    borderRadius: '10px',
    overflow: 'hidden'
};

const BusinessListByZip = () => {
    const { zipCode } = useParams();
    const [businesses, setBusinesses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [center, setCenter] = useState(null);
    const navigate = useNavigate();
    const backendBaseUrl = 'http://0.0.0.0:8080';  // Update with your backend base URL

    useEffect(() => {
        const fetchBusinesses = async () => {
            setLoading(true);
            try {
                // Fetch businesses from your database by zip code
                const searchResults = await searchBusinesses(null, zipCode, null);
                setBusinesses(searchResults);

                // Geocode the zip code to get lat/lng if no businesses have coordinates
                if (searchResults.length > 0) {
                    // If the first business has coordinates, center the map there
                    const { latitude, longitude } = searchResults[0];
                    if (latitude && longitude) {
                        setCenter({ lat: latitude, lng: longitude });
                    } else {
                        // Fallback to geocoding the zip code if no coordinates are available
                        const zipCoordinates = await geocodeZipCodeWithOpenCage(zipCode);
                        setCenter(zipCoordinates);
                    }
                } else {
                    // If no businesses are returned, fallback to geocoding the zip code
                    const zipCoordinates = await geocodeZipCodeWithOpenCage(zipCode);
                    setCenter(zipCoordinates);
                }
            } catch (error) {
                console.error('Error fetching businesses:', error);
                setBusinesses([]);
            } finally {
                setLoading(false);
            }
        };

        fetchBusinesses();
    }, [zipCode]);

    // Helper function to format phone numbers
    const formatPhoneNumber = (phoneNumber) => {
        if (phoneNumber && phoneNumber.length === 10) {
            return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`;
        }
        return phoneNumber;
    };

    // Handle button click to navigate to the new page
    const handleViewMoreBusinesses = () => {
        navigate(`/more-businesses/${zipCode}`);
    };

    return (
        <div className="business-list-container">
            <Navbar />
            <h1>Your Local Experts...</h1>
            <SearchBar />
            <main className="business-map-container">
                <div className="business-list-column">
                    <h3 className="zip-code-title">
                        Our Businesses in Zip Code: {zipCode}
                    </h3>

                    {loading ? (
                        <p>Loading businesses...</p>
                    ) : businesses.length > 0 ? (
                        <>
                            <div className="business-list">
                                {/* Display your own businesses */}
                                {businesses.map((business) => (
                                    <div key={business.id} className="business-card">

                                        <div
                                            key={business.id}
                                            className="business-card"
                                            onClick={() => {
                                                const websiteUrl = business.website.startsWith('http')
                                                    ? business.website
                                                    : `https://${business.website}`;
                                                window.open(websiteUrl, '_blank', 'noopener,noreferrer');
                                            }}
                                            style={{cursor: 'pointer'}} // Changes cursor to indicate clickability
                                        >
                                            <div>
                                                {business.imagePath && (
                                                    <img
                                                        src={`${backendBaseUrl}${business.imagePath}`}
                                                        alt={`${business.name} logo`}
                                                        className="business-logo"
                                                        style={{width: '100px', height: '100px', objectFit: 'cover'}}
                                                    />
                                                )}
                                            </div>
                                            <div className="business-card-right" style={{paddingLeft: '10px'}}>
                                                <h4>{business.name}</h4>
                                                <p><strong>Address:</strong> {business.address1}</p>
                                                <p><strong>Zip Code:</strong> {business.zipCode}</p>
                                                <p><strong>Type:</strong> {business.type}</p>
                                                <p><strong>Phone:</strong> <a href={`tel:${business.phoneNumber}`}
                                                                              className="link">
                                                    {formatPhoneNumber(business.phoneNumber)}</a></p>
                                                <p><strong>Website:</strong>{' '}
                                                    <a href={business.website.startsWith('http') ? business.website : `https://${business.website}`}
                                                       target="_blank" rel="noopener noreferrer" className="link">
                                                        {business.website}
                                                    </a>
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                                <button onClick={handleViewMoreBusinesses} className="button" >
                                    More Businesses in Your Area
                                </button>
                            </div>
                        </>
                    ) : (
                        <p>Sorry, we don't have businesses in this zip code yet!</p>
                    )}
                </div>

                <div className="map-column">
                    {center && (
                        <MapContainer center={center} zoom={12} style={containerStyle}>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />

                            {/* Add markers for your local businesses */}
                            {businesses
                                .filter(business => business.latitude && business.longitude)
                                .map((business) => (
                                    <Marker
                                        key={business.id}
                                        position={[business.latitude, business.longitude]}
                                        icon={customIcon}
                                    >
                                        <Popup>
                                            <strong>{business.name}</strong><br />
                                            {business.address1}
                                        </Popup>
                                    </Marker>
                                ))}
                        </MapContainer>
                    )}
                </div>
            </main>
            <footer className="footer">
                <p>&copy; {new Date().getFullYear()} Your Local Trusted Expert. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default BusinessListByZip;
