import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/navbar.scss';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
        console.log("Menu toggle clicked, isOpen:", !isOpen); // Add this to check state changes
    };

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <a href="/">Your Local Trusted Expert</a>
            </div>
            <div className="hamburger-menu" onClick={toggleMenu}>
                ☰
            </div>
            <ul className={`navbar-links ${isOpen ? 'show' : 'close'}`}>
                <li><a href="/" onClick={toggleMenu}>Home</a></li>
                <li><Link to="/businesses" onClick={toggleMenu}>Businesses</Link></li>
                <li><a href="/login" onClick={toggleMenu}>Log In</a></li>
                <li><a href="/signup" onClick={toggleMenu}>Join</a></li>
            </ul>
        </nav>
    );
};

export default Navbar;