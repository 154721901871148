import React, {useCallback, useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import { searchBusinesses } from '../services/BusinessServices'; // Assuming you're using this for searching by name, zip, and type
import { geocodeLatLngWithOpenCage} from '../components/geocodeApi'; // Your geocoding function to convert lat/lng to zip code
import Navbar from '../components/Navbar';
import '../styles/common.scss';
import SearchBar from "../components/SearchBar"; // Assuming you have a separate stylesheet for styling

const BusinessListByType = () => {
    const { type } = useParams(); // Get the business type from the URL params
    const [businesses, setBusinesses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const backendBaseUrl = 'http://0.0.0.0:8080';  // Update with your backend base URL

    // Fetch businesses by type
    const fetchBusinessesByType = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const businessesByType = await searchBusinesses(null, null, type);
            setBusinesses(businessesByType);
        } catch (error) {
            console.error('Error fetching businesses by type:', error);
            setError('Error fetching businesses');
            setBusinesses([]);
        } finally {
            setLoading(false);
        }
    }, [type]);

    // Fetch businesses by user's location (zip code) and type
    const fetchBusinessesNearMe = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const { latitude, longitude } = position.coords;
                try {
                    const zipCode = await geocodeLatLngWithOpenCage(latitude, longitude);
                    if (zipCode) {
                        const businessesNearMe = await searchBusinesses(null, zipCode, type);
                        setBusinesses(businessesNearMe);
                    } else {
                        setError('Could not determine your location.');
                    }
                } catch (error) {
                    setError('Error fetching nearby businesses.');
                }
            }, () => {
                setError('Geolocation not supported or permission denied.');
            });
        } else {
            setError('Geolocation not supported by your browser.');
        }
    };

    useEffect(() => {
        // Fetch all businesses by type when the component mounts
        fetchBusinessesByType();
    }, [fetchBusinessesByType]);
    // Utility function to format the phone number
    const formatPhoneNumber = (phoneNumber) => {
        if (phoneNumber && phoneNumber.length === 10) {
            return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`;
        }
        return phoneNumber; // Return the original number if not 10 digits
    };

    return (
        <div className="business-list-container">
            <Navbar />
            <h1>Your Local Experts...</h1>
            <SearchBar />
            <main>
                <h3>
                    Businesses by Type: {type}
                </h3>

                {/* Button to fetch nearby businesses */}
                <button onClick={fetchBusinessesNearMe} className="button">
                    Near Me
                </button>

                {loading ? (
                    <p>Loading businesses...</p>
                ) : error ? (
                    <p>{error}</p>
                ) : businesses.length > 0 ? (
                    <div className="business-list">
                        {businesses.map((business) => (
                            <div key={business.id} className="business-card">
                                <div>
                                    {business.imagePath && (
                                        <img
                                            src={`${backendBaseUrl}${business.imagePath}`}
                                            alt={`${business.name} logo`}
                                            className="business-logo"
                                            style={{width: '100px', height: '100px', objectFit: 'cover'}}
                                        />
                                    )}
                                </div>
                                <div className="business-card-right" style={{paddingLeft: '10px'}}>
                                    <p>
                                        <Link
                                            to={`/business/${business.id}`}  // Use Link component to redirect to detail page
                                            className="link"
                                        >
                                            {business.name}
                                        </Link>
                                    </p>
                                    <p><strong>Address:</strong> {business.address1}, {business.address2}</p>
                                    <p><strong>Zip Code:</strong> {business.zipCode}</p>
                                    <p><strong>Type:</strong> {business.type}</p>
                                    <p><strong>Phone:</strong> <a href={`tel:${business.phoneNumber}`}
                                                                  className="link">{formatPhoneNumber(business.phoneNumber)}</a>
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>No businesses found for this type.</p>
                )}
            </main>
            <footer className="footer">
                <p>&copy; {new Date().getFullYear()} Your Local Trusted Expert. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default BusinessListByType;
