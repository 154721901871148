import React from "react";
import axios from "axios";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render shows a fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Log the error to the server
        this.logErrorToServer(error, errorInfo);
    }

    logErrorToServer = (error, errorInfo) => {
        // Send error details to the server
        axios.post("/api/logError", {
            message: error.message,
            stack: errorInfo.componentStack,
            time: new Date().toISOString()
        });
    };

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;