import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { searchBusinesses } from '../services/BusinessServices';

const SearchBar = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const debounceTimeoutRef = useRef(null);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        debounceTimeoutRef.current = setTimeout(() => {
            if (searchTerm) {
                searchBusinesses(searchTerm.trim(), '', '')
                    .then((filteredBusinesses) => {
                        setSearchResults(filteredBusinesses);
                        setShowDropdown(true);
                    })
                    .catch((error) => {
                        console.error("Error searching businesses:", error);
                        setSearchResults([]);
                        setShowDropdown(false);
                    });
            } else {
                setSearchResults([]);
                setShowDropdown(false);
            }
        }, 300);

        return () => clearTimeout(debounceTimeoutRef.current);
    }, [searchTerm]);

    const handleSearch = () => {
        if (zipCode) {
            navigate(`/businesses/zipcode/${zipCode}`);
        } else if (searchTerm && !zipCode) {
            setShowDropdown(true);
        }
    };

    const handleSelectBusiness = (business) => {
        setShowDropdown(false);
        setSearchTerm('');
        setZipCode('');
        if (business && business.id) {
            navigate(`/business/${business.id}`);
        }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', position: 'relative' }}>
            <div style={{ display: 'flex', marginBottom: '10px' }}>
                <input
                    type="text"
                    placeholder="Search by name..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{
                        padding: '8px',
                        marginRight: '8px',
                        width: '40%',
                        borderRadius: showDropdown && searchResults.length > 0 ? '8px 8px 0 0' : '8px',
                    }}
                />
                <input
                    type="text"
                    placeholder="Search by Zip Code..."
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    style={{ padding: '8px', marginLeft: '8px', width: '40%' }}
                />
                <button onClick={handleSearch} className="button">
                    <i className="fas fa-search" style={{ fontSize: '16px' }}></i>
                </button>
            </div>

            {showDropdown && searchResults.length > 0 && (
                <div
                    ref={dropdownRef}
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        backgroundColor: '#fff',
                        border: '1px solid #ddd',
                        borderTop: 'none',
                        borderRadius: '0 0 8px 8px',
                        maxHeight: '200px',
                        overflowY: 'auto',
                        width: '40%',  // Same width as the name search bar
                        zIndex: 1000,
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        fontFamily: 'Inter, sans-serif',
                    }}
                >
                    {searchResults.map((business) => (
                        <div
                            key={business.id}
                            onClick={() => handleSelectBusiness(business)}
                            style={{
                                padding: '10px',
                                cursor: 'pointer',
                                color: '#000',
                                backgroundColor: '#fff',
                                transition: 'background-color 0.2s ease-in-out',
                            }}
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#fff'}
                        >
                            {business.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SearchBar;
