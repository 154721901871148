import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '../styles/common.scss';
import { geocodeZipCodeWithOpenCage } from '../components/geocodeApi';
import SearchBar from "../components/SearchBar";

// Reset Leaflet's default icon paths
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png'
});

// Custom marker icon (optional)
const customIcon = new L.Icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    shadowSize: [41, 41]
});

const containerStyle = {
    width: '100%',
    height: '400px',
    borderRadius: '10px',
    overflow: 'hidden'
};

const BusinessByApi = () => {
    const { zipCode } = useParams();
    const [externalBusinesses, setExternalBusinesses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [center, setCenter] = useState(null);
    const [query, setQuery] = useState('services'); // Default type of businesses
    const navigate = useNavigate();

    // Array of business types for dropdown
    const businessTypes = ['services', 'restaurants', 'retail', 'health', 'education'];

    useEffect(() => {
        const fetchBusinesses = async () => {
            setLoading(true);
            try {
                const zipCoordinates = await geocodeZipCodeWithOpenCage(zipCode);
                setCenter(zipCoordinates);
                await fetchExternalBusinesses(zipCoordinates.lat, zipCoordinates.lng, query);
            } catch (error) {
                console.error('Error fetching businesses:', error);
                setExternalBusinesses([]);
            } finally {
                setLoading(false);
            }
        };

        fetchBusinesses();
    }, [zipCode, query]); // Re-fetch when query changes

    const fetchExternalBusinesses = async (lat, lng, query) => {
        try {
            const response = await fetch(`https://maps-data.p.rapidapi.com/nearby.php?query=${query}&lat=${lat}&lng=${lng}&limit=10&country=us&lang=en&offset=0&zoom=12`, {
                method: 'GET',
                headers: {
                    'x-rapidapi-key': '617fe9f6f7msha351970db2f1a76p19ae76jsnc79442a4c7b1',
                    'x-rapidapi-host': 'maps-data.p.rapidapi.com'
                }
            });

            const externalData = await response.json();
            setExternalBusinesses(externalData.data || []);
        } catch (error) {
            console.error('Error fetching external businesses:', error);
        }
    };

    // Update query based on dropdown selection
    const handleTypeChange = (event) => {
        setQuery(event.target.value);
    };

    // Handle navigation back to the previous page or a specific route
    const handleBackClick = () => {
        navigate(`/businesses/zipcode/${zipCode}`); // Adjust the route as needed
    };

    console.log(externalBusinesses)

    return (

        <div className="more-businesses-container">
            <Navbar/>
            <h1 style={{fontFamily: "Inter,sans-serif", padding: "10px 20px",}}>More Businesses in Your Area</h1>

            <div style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                maxWidth: "1200px",
                margin: "0 auto",
                padding: "10px 20px"
            }}>
                <SearchBar/>
                <button onClick={handleBackClick} className="button">
                    Back
                </button>
            </div>

            {/* Dropdown to select business type */}
            <div style={{textAlign: 'center',     padding: "10px 20px", margin: '20px 0',marginLeft:'10px', width: "25%"}}>
                <label htmlFor="businessType" style={{marginRight: '10px', fontFamily: 'Inter, sans-serif'}}>Select
                    Business Type:</label>
                <select id="businessType" value={query} onChange={handleTypeChange}
                        style={{padding: '8px', fontFamily: 'Inter, sans-serif'}}>
                    {businessTypes.map(type => (
                        <option key={type} value={type}>{type.charAt(0).toUpperCase() + type.slice(1)}</option>
                    ))}
                </select>
            </div>

            <main className="business-map-container">
                <div className="business-list-column">
                    <h3 className="zip-code-title">
                        {query.charAt(0).toUpperCase() + query.slice(1)} Businesses in Zip Code: {zipCode}
                    </h3>
                    {loading ? (
                        <p>Loading businesses...</p>
                    ) : externalBusinesses.length > 0 ? (
                        <div className="business-list">
                            {externalBusinesses.map((externalBusiness, index) => (
                                <div key={index} className="business-card" onClick={() => {
                                    const websiteUrl = externalBusiness.website.startsWith('http')
                                        ? externalBusiness.website
                                        : `https://${externalBusiness.website}`;
                                    window.open(websiteUrl, '_blank', 'noopener,noreferrer');
                                }} style={{cursor: 'pointer'}}>
                                    <div>
                                        {externalBusiness.photos && externalBusiness.photos.length > 0 ? (
                                            <img
                                                src={externalBusiness.photos[0].src}
                                                alt={`${externalBusiness.name} logo`}
                                                className="business-logo"
                                                style={{width: '100px', height: '100px', objectFit: 'cover'}}
                                            />
                                        ) : (
                                            <div style={{
                                                width: '100px',
                                                height: '100px',
                                                backgroundColor: '#ccc'
                                            }}/>
                                        )}
                                    </div>
                                    <div className="business-card-right" style={{paddingLeft: '10px'}}>
                                        <h4>{externalBusiness.name}</h4>
                                        <p><strong>Address:</strong> {externalBusiness.full_address}</p>
                                        <p><strong>Phone:</strong> {externalBusiness.phone_number || 'N/A'}</p>
                                        {externalBusiness.website && ( // Conditionally render this section if website is not null
                                            <p><strong>Website:</strong>{' '}
                                                <a href={externalBusiness.website} target="_blank" rel="noopener noreferrer" className="link">
                                                    {externalBusiness.website}
                                                </a>
                                            </p>
                                        )}
                                    </div>
                                </div>

                            ))}
                        </div>
                    ) : (
                        <p>No businesses found for this zip code!</p>
                    )}
                </div>

                <div className="map-column">
                    {center && (
                        <MapContainer center={center} zoom={12} style={containerStyle}>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            {externalBusinesses
                                .filter(externalBusiness => externalBusiness.latitude && externalBusiness.longitude)
                                .map((externalBusiness, index) => (
                                    <Marker
                                        key={index}
                                        position={[externalBusiness.latitude, externalBusiness.longitude]}
                                        icon={customIcon}
                                    >
                                        <Popup>
                                            <strong>{externalBusiness.name}</strong><br/>
                                            {externalBusiness.full_address}
                                        </Popup>
                                    </Marker>
                                ))}
                        </MapContainer>
                    )}
                </div>
            </main>
            <footer className="footer">
                <p>&copy; {new Date().getFullYear()} Your Local Trusted Expert. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default BusinessByApi;
