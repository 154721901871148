import React, { useState } from 'react';

const BusinessModal = ({ isOpen, onClose, onSubmit, business, setBusiness, mode }) => {
    const [image, setImage] = useState(null); // Store the selected image
    const [imagePreview, setImagePreview] = useState(''); // Image preview URL
    const [errorMessage, setErrorMessage] = useState(''); // Error message for image

    const businessTypes = ['Retail', 'Service', 'Manufacturing', 'Hospitality', 'Construction', 'Other'];

    const backendBaseUrl = 'http://0.0.0.0:8080'; // Update with your backend base URL

    if (!isOpen) return null; // Don't render modal if not open

    // Handle input changes for business fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBusiness((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // Handle image file selection
    const handleImageChange = (e) => {
        const file = e.target.files[0]; // Get the first file from the input
        if (!file) return;

        // Validate image file type and size
        if (!file.type.startsWith('image/')) {
            setErrorMessage('Please select a valid image file.');
            setImage(null);
            return;
        }

        const maxSize = 2 * 1024 * 1024; // 2MB
        if (file.size > maxSize) {
            setErrorMessage('File size should be less than 2MB.');
            setImage(null);
            return;
        }

        // Set the image file and create a preview
        setImage(file); // Store the file object in state
        setImagePreview(URL.createObjectURL(file)); // Create a preview of the image
        setErrorMessage('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create FormData object to send both business data and image file
        const formData = new FormData();

        // Append business data to FormData
        Object.keys(business).forEach((key) => {
            formData.append(key, business[key]);
        });

        // Append the image file if it exists
        if (image) {
            formData.append('file', image); // Append the image file to the FormData
        }

        // Submit the business data and image as FormData
        try {
            await onSubmit(formData); // Pass FormData to onSubmit (handleCreateBusiness)
            setImage(null);
            setImagePreview('');
            setErrorMessage('');
        } catch (error) {
            console.error('Error creating business:', error);
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>{mode === 'edit' ? `Edit: ${business.name}` : 'Create New Business'}</h2>
                <form onSubmit={handleSubmit}>
                    {/* Form Fields */}
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="name">Name:</label>
                            <input
                                id="name"
                                type="text"
                                name="name"
                                value={business.name || ''}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="address1">Address 1:</label>
                            <input
                                id="address1"
                                type="text"
                                name="address1"
                                value={business.address1 || ''}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="address2">Address 2:</label>
                            <input
                                id="address2"
                                type="text"
                                name="address2"
                                value={business.address2 || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="city">City:</label>
                            <input
                                id="city"
                                type="text"
                                name="city"
                                value={business.city || ''}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="state">State:</label>
                            <input
                                id="state"
                                type="text"
                                name="state"
                                value={business.state || ''}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="zipCode">Zip Code:</label>
                            <input
                                id="zipCode"
                                type="text"
                                name="zipCode"
                                value={business.zipCode || ''}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="type">Business Type:</label>
                            <select
                                id="type"
                                name="type"
                                value={business.type || ''}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="" disabled>
                                    Select a type
                                </option>
                                {businessTypes.map((type) => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="phoneNumber">Phone Number:</label>
                            <input
                                id="phoneNumber"
                                type="text"
                                name="phoneNumber"
                                value={business.phoneNumber || ''}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input
                                id="email"
                                type="email"
                                name="email"
                                value={business.email || ''}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="website">Website:</label>
                            <input
                                id="website"
                                type="text"
                                name="website"
                                value={business.website || ''}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="contactName">Contact Name:</label>
                            <input
                                id="contactName"
                                type="text"
                                name="contactName"
                                value={business.contactName || ''}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="contactPhone">Contact Phone Number:</label>
                            <input
                                id="contactPhone"
                                type="text"
                                name="contactPhone"
                                value={business.contactPhone || ''}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="file">Upload Image:</label>
                        <input
                            id="file"
                            type="file"
                            name="imagePath"
                            accept="image/*"
                            onChange={handleImageChange}
                        />
                        {imagePreview && (
                            <div className="image-preview">
                                <img src={imagePreview} alt="Preview" />
                            </div>
                        )}
                        {business.imagePath && !imagePreview && (
                            <div className="image-preview">
                                <img
                                    src={`${backendBaseUrl}${business.imagePath}`}
                                    alt="Current Logo"
                                />
                            </div>
                        )}
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </div>

                    <div className="modal-button-group">
                        <button type="submit" className="submit-button">
                            {mode === 'edit' ? 'Update' : 'Create'}
                        </button>
                        <button type="button" className="cancel-button" onClick={onClose}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default BusinessModal;