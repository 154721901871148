import React, { useState, useEffect } from 'react';
import {Link, useParams} from 'react-router-dom';
import { getBusinessById, getBusinessesByType } from '../services/BusinessServices';
import '../styles/BusinessDetail.scss';
import Navbar from '../components/Navbar';
import SearchBar from "../components/SearchBar";

const BusinessDetail = () => {
    const { id } = useParams();
    const [business, setBusiness] = useState(null);
    const [relatedBusinesses, setRelatedBusinesses] = useState([]);

    const backendBaseUrl = 'http://0.0.0.0:8080';  // Update with your backend base URL

    useEffect(() => {
        const fetchBusinessDetails = async () => {
            try {
                const data = await getBusinessById(id);
                console.log(data)
                setBusiness(data);

                if (data && data.type) {
                    await fetchRelatedBusinessesByType(data.type, data.id);
                }
            } catch (error) {
                console.error("Error fetching business details:", error);
            }
        };

        fetchBusinessDetails();
    }, [id]);

    const fetchRelatedBusinessesByType = async (type, currentBusinessId) => {
        try {
            const related = await getBusinessesByType(type);
            const filtered = related.filter(b => b.id !== currentBusinessId);
            setRelatedBusinesses(filtered);
        } catch (error) {
            console.error("Error fetching related businesses:", error);
        }
    };

    // Utility function to format the phone number
    const formatPhoneNumber = (phoneNumber) => {
        if (phoneNumber && phoneNumber.length === 10) {
            return `(${phoneNumber.substring(0, 3)})${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`;
        }
        return phoneNumber; // Return the original number if not 10 digits
    };

    return (
        <div className="business-detail-container">
            <Navbar/>
            <h1>Your local experts...</h1>
            <SearchBar/>
            {business && (
                <div className="business-info">
                    <div className="info-container">
                        <div className="image-container">
                            {/* Display the image on the far left */}
                            {business.imagePath && (
                                <img
                                    src={`${backendBaseUrl}${business.imagePath}`}
                                    style={{width: "75%", height: "75%"}}// Use the correct URL
                                    alt={`${business.name} logo`}
                                    className="business-logo"
                                />
                            )}

                        </div>
                        <div className="left-container">
                            <h3>{business.name}</h3>
                            <p><strong>Type:</strong> {business.type}</p>
                            <p>
                                <strong>Address:</strong>{' '}
                                <a
                                    href={`https://www.google.com/maps?q=${encodeURIComponent(business.address)}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="link"
                                >
                                    {business.address1}, {business.address2}
                                </a>
                            </p>
                            <p>
                                <strong>Phone:</strong>{' '}
                                <a className="link" href={`tel:${business.phoneNumber}`}>
                                    {formatPhoneNumber(business.phoneNumber)}
                                </a>
                            </p>
                            <p>
                                <strong>Website:</strong>{' '}
                                <a
                                    href={business.website.startsWith('http') ? business.website : `https://${business.website}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="link"
                                >
                                    {business.website}
                                </a>
                            </p>
                        </div>
                        <div className="middle-container">
                            <p>
                                <strong>Email:</strong>{' '}
                                <a className="link" href={`mailto:${business.email}`}>
                                    {business.email}
                                </a>
                            </p>
                            <p><strong>Contact Name:</strong> {business.contactName}</p>
                            <p>
                                <strong>Contact Phone:</strong>{' '}
                                <a className="link" href={`tel:${business.contactPhone}`}>
                                    {formatPhoneNumber(business.contactPhone)}
                                </a>
                            </p>
                        </div>
                        <div className="right-container">
                            <p>About Company / Reviews</p>
                        </div>
                    </div>
                </div>
            )}
            <hr className="separator-line"/>

            {relatedBusinesses.length > 0 && (
                <div className="related-businesses-container">
                    <h2>Other {business.type} businesses nearby:</h2>
                    <div className="related-businesses-grid">
                        {relatedBusinesses.map((relatedBusiness) => (
                            <div className="related-business-card" key={relatedBusiness.id}>
                                <strong> <Link
                                    to={`/business/${relatedBusiness.id}`}  // Use Link component to redirect to detail page
                                    className="link">
                                    {relatedBusiness.name}
                                </Link></strong>
                                <p>
                                    <a
                                        href={`https://www.google.com/maps?q=${encodeURIComponent(relatedBusiness.address)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="link"
                                    >
                                        {relatedBusiness.address}
                                    </a>
                                </p>
                                <p><a className="link"
                                      href={`mailto:${relatedBusiness.email}`}>{relatedBusiness.email}</a></p>
                                <p><a className="link"
                                      href={`tel:${relatedBusiness.phoneNumber}`}>{formatPhoneNumber(relatedBusiness.phoneNumber)}</a>
                                </p>
                                <p>
                                    <a
                                        href={relatedBusiness.website.startsWith('http') ? relatedBusiness.website : `https://${relatedBusiness.website}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="link"
                                    >
                                        {relatedBusiness.website}
                                    </a>
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default BusinessDetail;
