import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/signup.scss';
import BusinessModal from './BusinessModal';  // Assuming this is where your BusinessModal is located

const Signup = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showBusinessModal, setShowBusinessModal] = useState(false); // State to manage business modal
    const [showOptionsModal, setShowOptionsModal] = useState(false); // State to manage the options modal
    const [currentBusiness, setCurrentBusiness] = useState({
        name: '',
        address: '',
        phoneNumber: '',
        email: '',
        contactName: '',
        contactPhone: '',
        imagePath: ''
    });

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault(); // Only prevent default if it's a form event
        }

        // Perform the submit logic
        axios.post(`${apiUrl}/api/Business`, currentBusiness)
            .then(() => {
                console.log('Business created');
                setShowBusinessModal(false);
                navigate('/'); // Redirect to home after business creation
            })
            .catch((error) => {
                console.error('Error creating business:', error);
            });
    };

    // Handle the decision to create a business
    const handleCreateBusiness = () => {
        setShowOptionsModal(false); // Close options modal
        setShowBusinessModal(true); // Show business modal
    };

    // Handle the decision to go to the home page
    const handleSkipBusinessCreation = () => {
        setShowOptionsModal(false); // Close options modal
        navigate('/'); // Redirect to home page
    };

    return (
        <div className="signup-root">
            <div className="signup-app">
                <div className="signup-container">
                    <div className="signup-card">
                        <h1 className="signup-title">Sign up</h1>
                        <p className="signup-subtitle">Create your account</p>
                        <form onSubmit={handleSubmit}>
                            <div className="input-group">
                                <input
                                    type="email"
                                    placeholder="Email Address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    className="signup-input"
                                />
                            </div>
                            <div className="input-group">
                                <input
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    className="signup-input"
                                />
                            </div>
                            <button type="submit" className="signup-button">Sign up</button>
                        </form>
                    </div>
                </div>
            </div>

            {/* Options Modal */}
            {showOptionsModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>What would you like to do next?</h2>
                        <button onClick={handleCreateBusiness} className="modal-button">Create a Business</button>
                        <button onClick={handleSkipBusinessCreation} className="modal-button">Go to Home</button>
                    </div>
                </div>
            )}

            {/* Business Creation Modal */}
            {showBusinessModal && (
                <BusinessModal
                    isOpen={showBusinessModal}
                    onClose={handleSkipBusinessCreation}  // Option to skip
                    onSubmit={handleCreateBusiness}      // Business creation function
                    business={currentBusiness}
                    setBusiness={setCurrentBusiness}
                    mode="create"
                />
            )}
        </div>
    );
};

export default Signup;