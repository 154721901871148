import React, { useState, useEffect, useRef, useCallback } from 'react';
import { getAllBusinesses, getAllBusinessTypes, searchBusinesses } from '../services/BusinessServices';
import '../styles/common.scss';
import Navbar from "../components/Navbar";
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [businessTypes, setBusinessTypes] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const navigate = useNavigate();

    const dropdownRef = useRef(null);
    const debounceTimeoutRef = useRef(null);

    useEffect(() => {
        fetchData();
        fetchBusinessTypes();
    }, []);

    const fetchData = () => {
        getAllBusinesses();
    };

    const fetchBusinessTypes = () => {
        getAllBusinessTypes()
            .then((types) => {
                setBusinessTypes(types);
            })
            .catch((error) => {
                console.error('Error fetching business types:', error);
            });
    };

    const handleSearch = useCallback(() => {
        if (selectedType) {
            navigate(`/businesses/type/${selectedType}`);
        } else if (zipCode) {
            navigate(`/businesses/zipcode/${zipCode}`);
        } else {
            const searchCriteria = {
                name: searchTerm.trim(),
                zipCode: zipCode.trim(),
                type: selectedType.trim(),
            };

            searchBusinesses(searchCriteria.name, searchCriteria.zipCode, searchCriteria.type)
                .then((filteredBusinesses) => {
                    setSearchResults(filteredBusinesses);
                    setShowDropdown(true);
                })
                .catch((error) => {
                    console.error("Error searching businesses:", error);
                    setSearchResults([]);
                    setShowDropdown(false);
                });
        }
    }, [navigate, searchTerm, zipCode, selectedType]);

    const handleSelectBusiness = (business) => {
        setShowDropdown(false);
        navigate(`/business/${business.id}`);
        setSearchTerm('');
        setZipCode('');
        setSelectedType('');
    };

    useEffect(() => {
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        debounceTimeoutRef.current = setTimeout(() => {
            if (searchTerm) {
                handleSearch();
            } else {
                setSearchResults([]);
                setShowDropdown(false);
            }
        }, 300);

        return () => clearTimeout(debounceTimeoutRef.current);
    }, [searchTerm, handleSearch]);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div className="App" style={{ backgroundColor: '#606c38', color: '#fefae0' }}>
            <Navbar />
            <main>
                <h1 style={{ textAlign: 'center', marginBottom: '20px', fontFamily: 'Inter, sans-serif', color: '#283618' }}>
                    Helping you find the closest expert in your area
                </h1>
                <div className="search-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
                    <div className="search-bar-container" style={{ position: 'relative', width: '100%', maxWidth: '800px' }}>
                        <input
                            type="text"
                            placeholder="Search by name..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{
                                padding: '10px',
                                width: '100%',
                                borderRadius: showDropdown && searchResults.length > 0 ? '8px 8px 0 0' : '8px',
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                            }}
                        />
                        {showDropdown && searchResults.length > 0 && (
                            <div
                                ref={dropdownRef}
                                style={{
                                    position: 'absolute',
                                    top: '100%',
                                    left: 0,
                                    backgroundColor: '#fff',
                                    border: '1px solid #ddd',
                                    borderTop: 'none',
                                    borderRadius: '0 0 8px 8px',
                                    maxHeight: '200px',
                                    overflowY: 'auto',
                                    width: '100%',
                                    zIndex: 1000,
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    fontFamily: 'Inter, sans-serif',
                                }}
                            >
                                {searchResults.map((business) => (
                                    <div
                                        key={business.id}
                                        onClick={() => handleSelectBusiness(business)}
                                        style={{
                                            padding: '10px',
                                            cursor: 'pointer',
                                            color: '#000',
                                            backgroundColor: '#fff',
                                            transition: 'background-color 0.2s ease-in-out',
                                        }}
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'}
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#fff'}
                                    >
                                        {business.name}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div style={{ display: 'flex', marginTop: '10px', width: '100%', maxWidth: '800px', alignItems: 'center' }}>
                        <input
                            type="text"
                            placeholder="Search by Zip Code..."
                            value={zipCode}
                            onChange={(e) => setZipCode(e.target.value)}
                            style={{
                                padding: '10px',
                                width: '40%',
                                marginRight: '8px',
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                borderRadius: '8px',
                            }}
                        />
                        <select
                            value={selectedType}
                            onChange={(e) => setSelectedType(e.target.value)}
                            style={{
                                padding: '10px',
                                width: '40%',
                                fontFamily: 'Inter, sans-serif',
                                fontSize: '16px',
                                borderRadius: '8px',
                                marginRight: '8px',
                            }}
                        >
                            <option value="">Select Business By Type...</option>
                            {businessTypes.map((type) => (
                                <option key={type.id} value={type.name}>
                                    {type}
                                </option>
                            ))}
                        </select>
                        {/* Search Button */}
                        <button onClick={handleSearch} className="button">
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </main>

            <footer style={{
                backgroundColor: '#283618',
                fontFamily: 'Inter, sans-serif',
                color: 'white',
                textAlign: 'center',
                padding: '10px 0',
            }}>
                <p>&copy; {new Date().getFullYear()} Your Local Trusted Expert. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default Home;
