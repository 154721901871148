import axios from "axios";

// Get the API URL from the environment variable
const apiUrl = process.env.REACT_APP_API_URL;

export const getAllBusinesses = async () => {
    try {
        const response = await axios.get(`${apiUrl}/BusinessEntity/Business/GetBusinesses`);
        return response.data;
    } catch (error) {
        console.error('Error fetching businesses:', error.response || error.message);
    }
}

export const getBusinessById = async (id) => {
    try {
        const response = await axios.get(`${apiUrl}/BusinessEntity/Business/GetBusinessById/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching Business:', error.response || error.message);
    }
}

// Get businesses by type
export const getBusinessesByType = async (type) => {
    try {
        const response = await axios.get(`${apiUrl}/BusinessEntity/Business/GetBusinessByType/${type}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching Business:', error.response || error.message);
    }
}

// Get all business types
export const getAllBusinessTypes = async () => {
    try {
        const response = await axios.get(`${apiUrl}/BusinessEntity/Business/GetAllBusinessTypes/GetAllBusinessTypes`);
        return response.data;
    } catch (error) {
        console.error('Error fetching business types:', error.response || error.message);
    }
};


export const deleteBusiness = async (id) => {
    try {
        const response = await axios.delete(`${apiUrl}/BusinessEntity/Business/DeleteBusiness/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const createBusiness = async (data) => {
    try {
        const response = await axios.post(`${apiUrl}/BusinessEntity/Business/CreateBusiness`, data,  {
            headers: {
                'Content-Type': 'multipart/form-data',  // Ensure multipart/form-data is set
            },
        });
        console.log('Response from API:', response.data);  // Log response data from backend

        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const updateBusiness = async (id, data) => {
    try {
        const response = await axios.put(`${apiUrl}/BusinessEntity/Business/UpdateBusiness/${id}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',  // Ensure multipart/form-data is set
            },
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;  // Ensure to throw the error for handling in the component
    }
};
export const searchBusinesses = async (name, zipCode, type) => {
    // Initialize an array to hold query parameters
    const queryParams = [];

    // Add parameters conditionally
    if (name) {
        queryParams.push(`name=${encodeURIComponent(name)}`);
    }

    if (zipCode) {
        queryParams.push(`zipCode=${encodeURIComponent(zipCode)}`);
    }

    if (type) {
        queryParams.push(`type=${encodeURIComponent(type)}`);
    }

    // Construct the query string
    const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';

    try {
        // Send the request to the backend
        const response = await axios.get(`${apiUrl}/BusinessEntity/Business/SearchBusinesses/search${queryString}`);
        return response.data; // Return the search results
    } catch (error) {
        console.error("Error searching businesses:", error);
        throw error; // Re-throw the error to handle it in the component
    }
};


// GeocodingService.js
export async function geocodeAddress(address) {
    try {
        const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}`);
        const data = await response.json();
        if (data && data.length > 0) {
            return { lat: parseFloat(data[0].lat), lng: parseFloat(data[0].lon) };
        }
    } catch (error) {
        console.error("Error geocoding address:", error);
    }
    return null;  // Fallback if no results found
}
