export const geocodeZipCodeWithOpenCage = async (zipCode) => {
    const apiKey = '97c99e9e6f1b46909306ff82fb1ac5ba';  // Replace with your OpenCage API key
    const apiUrl = `https://api.opencagedata.com/geocode/v1/json?q=${zipCode}&key=${apiKey}&countrycode=us&pretty=1`;

    try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        if (data.results && data.results.length > 0) {
            const { lat, lng } = data.results[0].geometry;
            return { lat, lng };
        } else {
            throw new Error('No location data found for the provided zip code');
        }
    } catch (error) {
        console.error('Error during geocoding:', error);
        throw error;
    }
};

// Reverse geocoding: Get zip code from latitude and longitude
export const geocodeLatLngWithOpenCage = async (latitude, longitude) => {
    const apiKey = '97c99e9e6f1b46909306ff82fb1ac5ba';  // Replace with your OpenCage API key
    const apiUrl = `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}&pretty=1&countrycode=us`;

    try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        if (data.results && data.results.length > 0) {
            const components = data.results[0].components;
            return components.postcode || null; // Return the zip code if available
        } else {
            throw new Error('No location data found for the provided coordinates');
        }
    } catch (error) {
        console.error('Error during reverse geocoding:', error);
        throw error;
    }
};
